import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
const UpgradeNow = ({ application, featuresController, subscriptionContoller }) => {
    const shouldShowCTA = !featuresController.hasFolders;
    const hasAccount = subscriptionContoller.hasAccount;
    const hasAccessToFeatures = subscriptionContoller.hasFirstPartyOnlineOrOfflineSubscription();
    const onClick = useCallback(() => {
        if (hasAccount && application.isNativeIOS()) {
            application.showPremiumModal();
        }
        else {
            void application.openPurchaseFlow();
        }
    }, [application, hasAccount]);
    if (!shouldShowCTA || hasAccessToFeatures) {
        return null;
    }
    return (_jsx("div", { className: "flex h-full items-center px-2", children: _jsx("button", { className: "rounded bg-info px-1.5 py-0.5 text-sm font-bold uppercase text-info-contrast hover:brightness-125 lg:text-xs", onClick: onClick, children: !hasAccount ? 'Sign up to sync' : 'U' }) }));
};
export default observer(UpgradeNow);
